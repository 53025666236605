import { Injectable } from '@angular/core';
import {firebase} from "@firebase/app";
import "@firebase/firestore";
import "@firebase/storage";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import { FirebaseFirestore } from '@firebase/firestore-types';
import { FirebaseStorage } from '@firebase/storage-types';
@Injectable({
  providedIn: 'root'
})
export class DbService {
  db: FirebaseFirestore;
  storage: FirebaseStorage;
  constructor() {
    var firebaseConfig = {
      apiKey: "AIzaSyCTOSNFwQQLTnto8eAcWTm-WftJakJBQ0g",
      authDomain: "aritmetest-ff078.firebaseapp.com",
      databaseURL: "https://aritmetest-ff078.firebaseio.com",
      projectId: "aritmetest-ff078",
      storageBucket: "aritmetest-ff078.appspot.com",
      messagingSenderId: "564583704317",
      appId: "1:564583704317:web:f94381792b164c47e50094",
      measurementId: "G-8633BCBH3L"
    };
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.storage = firebase.storage();
  }
}
