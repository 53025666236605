import { UserService } from './../Services/user-services.service';
import { DbService } from './../Services/db.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public userservice: UserService) {

  }

  ngOnInit() {
  }

}
