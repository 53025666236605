import { DbService } from './db.service';
import { Usuario } from './../Interfaces/usuario';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  Token: string = null;
  Usuario: Usuario = null;
  constructor(public crud: DbService) {
    this.Token = localStorage.getItem("Token");
    if(this.Token != null) {
      const userlogin = JSON.parse(this.Token);
      crud.db.collection("Usuarios").where("Correo", "==", userlogin.User).where("Pass", "==", userlogin.Pass).limit(1).get().then((res)=> {
        res.forEach((doc) => {
          this.Usuario = doc.data();
          delete this.Usuario.Pass;
        });
        if (res.size == 0) {
          this.Desconectar();
        }
      });
    }
  }
  Conectar(usuario: string, pass: string, exito, error) {
    this.crud.db.collection("Usuarios").where("Correo", "==", usuario).where("Pass", "==", pass).limit(1).get().then((res)=> {
      if (res.size > 0) {
        res.forEach((doc) => {
          this.Usuario = doc.data();
          const Token = {
            User: this.Usuario.Correo,
            Pass: this.Usuario.Pass
          }
          localStorage.setItem("Token", JSON.stringify(Token))
          delete this.Usuario.Pass;
          exito();
        });
      } else {
        error();
      }
    }).catch(()=>{
      error();
    });
  }
  Desconectar() {
    this.Usuario = null;
    localStorage.removeItem("Token");
  }
  async VerificarCorreo(correo: string, entonces, error) {
    this.crud.db.collection("Usuarios").where("Correo", "==", correo).get().then((res)=>{
      if(res.size == 0) {
        entonces();
      } else {
        error();
      }
    });
  }
  Registrar(nombre: string, usuario: string, pass: string, exito, error) {
    this.Usuario = {
      Nombre: nombre,
      Correo: usuario,
      Pass: pass
    }
    this.crud.db.collection("Usuarios").add(this.Usuario).then(()=>{
      exito();
    }).catch(()=>{
      error();
    });
  }
}
